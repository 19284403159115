'use strict';

angular.module('developerWebapp')
  .controller('LivetouchAboutCtrl',["$scope", "$routeParams", "$location", "$http", "$timeout", "simpleAuth", function ($scope, $routeParams, $location, $http, $timeout, simpleAuth) {

    // -- Page and menu handling
    // set lang for sub menu
    var updateLang = function () {
      $scope.pages = [
        {id: 'about', text: $scope.i18n.livetouch.about},
        {id: 'hardware', text: $scope.i18n.livetouch.hardware},
        {id: 'software', text: $scope.i18n.livetouch.software},
        {id: 'connect', text: $scope.i18n.livetouch.connect}
      ];
      $scope.aboutDocLink = 'views/doc/livetouch-about-'+$scope.i18n.lang+'.md';
      $scope.hardwareDocLink = 'views/doc/livetouch-hardware-'+$scope.i18n.lang+'.md';
      $scope.softwareDocLink = 'views/doc/livetouch-software-'+$scope.i18n.lang+'.md';
      $scope.connectDocLink = 'views/doc/livetouch-connect-'+$scope.i18n.lang+'.md';
    };
    $scope.$watch('i18n', updateLang);
    updateLang();

    $scope.currentPage = 'about';
    if (angular.isDefined($routeParams.page)) {
      $scope.currentPage = $routeParams.page;
    }

    $scope.activate = function (page) {
      $scope.currentPage = page;
      var search = $location.search();
      search.page = page;
      $location.search(search);
    };

    $scope.isActive = function (page) {
      return $scope.currentPage === page;
    };
    
    // Initialize page
    var startPage = $scope.pages[0].id;
    var param = $location.search().page;
    if (angular.isDefined(param)) {
      startPage = param;
    }
    $scope.activate(startPage);

    var menu = $('.livetouch-menu');
    var menuLimit = menu.position().top - 80;

    var adjustNavbar = function () {
      var scrollTop = document.body.scrollTop || document.documentElement.scrollTop;
      if (scrollTop > menuLimit) {
        menu.addClass('fixed');
      } else {
        menu.removeClass('fixed');
      }
    };

    $(window).scroll(adjustNavbar);
}]);
