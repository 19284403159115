'use strict';

angular.module('developerWebapp')
  .controller('LivetouchResCtrl',[ "$scope", "$routeParams", "$location", "$http", "$timeout", "simpleAuth", function ($scope, $routeParams, $location, $http, $timeout, simpleAuth) {

    // -- Page and menu handling
    // set lang for sub menu
    var updateLang = function () {
      $scope.pages = [
        {id: 'sdk', text: $scope.i18n.livetouch.getsdk},
        {id: 'samples', text: $scope.i18n.livetouch.samples}
      ];
      $scope.sdkDocLink = 'views/doc/livetouch-sdk-'+$scope.i18n.lang+'.md';
      $scope.samplesDocLink = 'views/doc/livetouch-samples-'+$scope.i18n.lang+'.md';
    };
    $scope.$watch('i18n', updateLang);
    updateLang();

    $scope.currentPage = 'sdk';
    if (angular.isDefined($routeParams.page)) {
      $scope.currentPage = $routeParams.page;
    }

    $scope.activate = function (page) {
      $scope.currentPage = page;
      var search = $location.search();
      search.page = page;
      $location.search(search);
    };

    $scope.isActive = function (page) {
      return $scope.currentPage === page;
    };
    
    // Initialize page
    var startPage = $scope.pages[0].id;
    var param = $location.search().page;
    if (angular.isDefined(param)) {
      startPage = param;
    }
    $scope.activate(startPage);

    var menu = $('.livetouch-menu');
    var menuLimit = menu.position().top - 80;

    var adjustNavbar = function () {
      var scrollTop = document.body.scrollTop || document.documentElement.scrollTop;
      if (scrollTop > menuLimit) {
        menu.addClass('fixed');
      } else {
        menu.removeClass('fixed');
      }
    };

    $(window).scroll(adjustNavbar);
}]);
