'use strict';

if (!String.prototype.format) {
  String.prototype.format = function() {
    var args = arguments;
    return this.replace(/{(\d+)}/g, function(match, number) {
      return angular.isDefined(args[number]) ? args[number] : match;
    });
  };
}
