'use strict';

angular.module('developerWebapp')
  .controller('OpenDataCtrl', ["$scope", "$routeParams", "$location", "$http", "$timeout", "simpleAuth", function ($scope, $routeParams, $location, $http, $timeout, simpleAuth) {

	$scope.baseAPI = window.config.base_api_url;

    // -- Page and menu handling
    // set lang for sub menu
    var updateLang = function () {
      $scope.pages = [
        {id: 'getstarted', text: $scope.i18n.opendata.getstarted},
        {id: 'static', text: $scope.i18n.opendata.static},
        {id: 'dynamic', text: $scope.i18n.opendata.dynamic},
        {id: 'changelog', text: $scope.i18n.opendata.changelog}
      ];
      $scope.dynamicDocLink = 'views/doc/opendata-dynamic-'+$scope.i18n.lang+'.md';
      $scope.getstartedDocLink = 'views/doc/opendata-getstarted-'+$scope.i18n.lang+'.md';
      $scope.changelogDocLink = 'views/doc/opendata-changelog-'+$scope.i18n.lang+'.md';
    };
    $scope.$watch('i18n', updateLang);
    updateLang();

    $scope.currentPage = 'getstarted';
    if (angular.isDefined($routeParams.page)) {
      $scope.currentPage = $routeParams.page;
    }

    $scope.activate = function (page) {
      $scope.currentPage = page;
      var search = $location.search();
      search.page = page;
      $location.search(search);
    };

    $scope.isActive = function (page) {
      return $scope.currentPage === page;
    };

    $scope.download = function (link) {
    	console.log(link);
    	window.open(link);
    };

    var currentCountDown;
    $scope.openModale = function (contract) {
      if (angular.isDefined(currentCountDown)) {
        $timeout.cancel(currentCountDown);
      }

      $scope.hasFtv = false;
      $scope.currentContract = contract;
      $location.search({page: $scope.currentPage, contract: contract.name});
      $('#staticModal').modal('show');
      var windowHeight = $(window).height();
      $('#staticModal .modal-body').css('max-height', (windowHeight * 70 / 100) + 'px');
      $('#staticModal a').click(function () {
        $('#staticModal').modal('hide');
      });

      var modaleMapOptions = {
        center: lyon,
        zoom: 14,
        mapTypeId: google.maps.MapTypeId.ROADMAP,
        streetViewControl: false,
        scrollwheel: true,
        disableDefaultUI: true
      };
      var modaleMap = new google.maps.Map(document.getElementById('contract-map-canvas'), modaleMapOptions);

      google.maps.event.trigger(modaleMap, 'resize');

      $http.get(window.config.base_api_url + '/rest/vls/stations/' + contract.name + '.json')
        .then(function(response) {
          var bounds = new google.maps.LatLngBounds();
          var markers = [];
          var data = response.data;
          for (var i = 0; i < data.length; i++) {
            var station = data[i];
            var latlng = new google.maps.LatLng(station.latitude, station.longitude);
            bounds.extend(latlng);
            markers.push(new google.maps.Marker({
              map: modaleMap,
              draggable: false,
              position: latlng,
              icon: 'images/marker2.png'
            }));
          }
          var options = {
            styles: [
              {
                width: 53,
                height: 52,
                url: 'images/marker_group.png'
              }
            ]
          };
          new MarkerClusterer(modaleMap, markers, options);
          modaleMap.fitBounds(bounds);
          modaleMap.setCenter(bounds.getCenter());
        });

      if (simpleAuth.isLoggedIn()) {
        $http.get(window.config.base_api_url + '/rest/users/' + simpleAuth.getParam('user-id'))
          .then(function(response) {
            var data = response.data;
            if (data.tenants !== null && $.inArray('ftv', data.tenants.modules) !== -1) {
              $scope.hasFtv = true;

              // Download links
              $http.get(window.config.base_api_url + '/rest/ftv/' + contract.name + '/files')
                .then(function (resp) {
                  $scope.expiration = resp.data.expirationDate - new Date().getTime();
                  $scope.links = resp.data;

                  var update = function () {
                    if ($scope.expiration > 0) {
                      currentCountDown = $timeout(update, 1000);
                    }
                  };
                  update();
                },function() {
                  $scope.hasFtv = false;
                });
            }
          });
      }
    };

    $scope.order = function () {
      return function (contract) {
        return contract.countryCode + contract.name;
      };
    };

    // Initialize page
    var startPage = $scope.pages[0].id;
    var param = $location.search().page;
    if (angular.isDefined(param)) {
      startPage = param;
    }
    $scope.activate(startPage);

    var contractName = $location.search().contract;

    var menu = $('.opendata-menu');
    var menuLimit = menu.position().top - 80;

    var adjustNavbar = function () {
      var scrollTop = document.body.scrollTop || document.documentElement.scrollTop;
      if (scrollTop > menuLimit) {
        menu.addClass('fixed');
      } else {
        menu.removeClass('fixed');
      }
    };

    $(window).scroll(adjustNavbar);

    var lyon = new google.maps.LatLng(45.75, 4.85);
    var mapOptions = {
      center: lyon,
      zoom: 14,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      streetViewControl: false,
      scrollwheel: false,
      disableDefaultUI: true,
      styles: [
        {
          stylers: [
            { saturation: -100 }
          ]
        },
        {
          featureType: 'road',
          stylers: [
            { lightness: 50 }
          ]
        },
        {
          featureType: 'transit',
          stylers: [
            { visibility: 'off' }
          ]
        },
        {
          elementType: 'labels',
          stylers: [
            { visibility: 'off' }
          ]
        }
      ]
    };

    var map = new google.maps.Map(document.getElementById('map-canvas'), mapOptions);

    // Download contracts
    $http.get(window.config.base_api_url + '/rest/vls/contracts')
      .then(function (response) {
        var data = response.data;
        $scope.contracts = data;
        if (angular.isDefined(contractName)) {
          for (var i = 0; i < data.length; i++) {
            if (data[i].name === contractName) {
              $scope.openModale(data[i]);
            }
          }
        }
      }, function (response) {
        $scope.contractsError = $scope.i18n.errors(response.data);
      });

    $http.get(window.config.base_api_url + '/rest/vls/stations/Lyon.json')
      .then(function (response) {
        var data = response.data;
        for (var i = 0; i < data.length; i++) {
          var station = data[i];
          new google.maps.Marker({
            map: map,
            draggable: false,
            position: new google.maps.LatLng(station.latitude, station.longitude),
            icon: 'images/marker.png'
          });
        }
      });
  }]);

