'use strict';

angular.module('developerWebapp')
  .controller('ContactCtrl', ['$scope', '$http', 'simpleAuth', function($scope, $http, simpleAuth){

    $scope.mail = simpleAuth.getParam('user-mail', '');

    $scope.sendMail = function(){
      $http
        .post(window.config.base_api_url + '/rest/contacts/', {
          'mail': $scope.mail,
          'name': $scope.firstName + ' ' + $scope.lastName,
          'subject': $scope.subject,
          'content': $scope.content
        }).then(function(){
          $scope.subject = '';
          $scope.content = '';
          $scope.contactForm.$setPristine();
          $scope.info = $scope.i18n.contact.ctrlSent;
        }, function(response){
          $scope.error = $scope.i18n.errors(response.data);
        });
    };
  }]);
