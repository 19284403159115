'use strict';

import { markdown } from 'markdown';

angular.module('developerWebapp')
  .directive('includeDoc', ['$http', function ($http) {

    var parse = function(scope, data) {
      var replacements = [];
      var i, j = 0;
      var value = '';

      var allMatches = data.match(/```(.+)((?:\r|.|\n)*?)```/gm) || [];

      for(i in allMatches){
        value = allMatches[i];
        data = data.replace(value, '{{'+j+'}}');
        replacements[j] = value.replace(/```(.+)((?:\r|.|\n)*)```/m, '<pre class="prettyprint lang-$1">$2</pre>');
        j++;
      }

      allMatches =  data.match(/```((?:\r|.|\n)*?)```/gm) || [];

      for(i in allMatches){
        value = allMatches[i];
        data = data.replace(value, '{{'+j+'}}');
        replacements[j] = value.replace(/```((?:\r|.|\n)*)```/m, '<pre class="prettyprint">$1</pre>');
        j++;
      }

      var output = markdown.toHTML( data, 'Maruku' );

      for (i = 0; i < j; i++){
        output = output.replace('{{'+i+'}}', replacements[i]);
      }

      var toReplace = output.match(/\{\{(.*?)\}\}/g) || [];

      for (i in toReplace){
        output = output.replace(toReplace[i], scope[toReplace[i].replace(/(\{|\})/g, '')]);
      }

      return output;
    };

    return {
      'restrict' : 'E',
      'compile' : function compile() {
        return {
            post: function postLink(scope, iElement, iAttrs) {
                    iElement.addClass('doc-content clearfix');
                    iAttrs.$observe('src', function(value) {

                      if(angular.isUndefined(value)) {
                        iElement.html(parse(scope, iElement.html()));
                        prettyPrint();
                        return;
                      }

                      $http.get(value).then(function(response){
                        iElement.html(parse(scope, response.data));
                        prettyPrint();
                      });
                    });
                  }
        }
      }
    };
  }]);
