'use strict';

global.jQuery = require('jquery');
require('angular');
require('angular-cookies');
require('angular-local-storage');
require('angular-resource');
require('angular-route');
require('angular-sanitize');
require('angular-base64');
require('angular-simple-auth/dist/angular-simple-auth.min.js');

require('bootstrap-sass/js/bootstrap-modal');
require('other_components/prettify/script/prettify.js');
require('other_components/markerclusterer');

require('other_components/prettify/css/prettify.css');
require('styles/main.scss')

require('components/utils');
require('components/ellipsis');

angular.module('developerWebapp', ['ngSanitize', 'ngRoute', 'simpleAuth', 'ngResource'])
  .filter('remainingTime', function () {
    return function (time) {
      var seconds = time / 1000;
      return parseInt(seconds);
    };
  })
  .filter('ftvLink', function (I18n) {
    return function (input, lang) {
      var months = I18n.en.month;
      if (lang === 'fr') {
        months = I18n.fr.month;
      }
      var start = input.lastIndexOf('/') + 1;
      var end = input.lastIndexOf('?');
      var date = input.substring(start, end);
      var year = date.substring(0, 4);
      var month = parseInt(date.substring(4, 6)) - 1;
      return months[month] + ' ' + year;
    };
  })
  .filter('stringSort', function () {
    return function (input) {
      if (angular.isUndefined(input)) {
        return null;
      }
      return input.sort();
    };
  })
  // Disable scroll on anchor change
  .value('$anchorScroll', angular.noop)
  .config([ '$routeProvider', '$locationProvider', 'simpleAuthProvider', function ($routeProvider, $locationProvider, simpleAuthProvider) {
    $locationProvider.hashPrefix('');
    $routeProvider
      .when('/home', {
        templateUrl: 'views/home.html',
        menu: 'home'
      }).when('/opendata', {
        redirectTo: '/opendata/vls'
      }).when('/opendata/license', {
        templateUrl: 'views/license.html',
        menu: 'opendata',
        submenu: 'license',
        reloadOnSearch: false
      }).when('/opendata/vls', {
        templateUrl: 'views/opendata.html',
        controller: 'OpenDataCtrl',
        menu: 'opendata',
        submenu: 'vls',
        reloadOnSearch: false
    }).when('/gbfs', {
       redirectTo: '/gbfs/usage'
    }).when('/gbfs', {
        templateUrl: 'views/gbfs.html',
        controller: 'GbfsDataCtrl',
        menu: 'gbfs',
        submenu: 'usage',
        reloadOnSearch: false
      }).when('/livetouch', {
    	  redirectTo: '/livetouch/about'
      }).when('/livetouch/about', {
        templateUrl: 'views/livetouch.html',
        controller: 'LivetouchAboutCtrl',
        menu: 'livetouch',
        submenu: 'about'
      }).when('/livetouch/design', {
        templateUrl: 'views/livetouch.html',
        controller: 'LivetouchDesignCtrl',
        menu: 'livetouch',
        submenu: 'design'
      }).when('/livetouch/documentation', {
        templateUrl: 'views/livetouch.html',
        controller: 'LivetouchDocsCtrl',
        menu: 'livetouch',
        submenu: 'documentation'
      }).when('/livetouch/resources', {
        templateUrl: 'views/livetouch.html',
        controller: 'LivetouchResCtrl',
        menu: 'livetouch',
        submenu: 'resources'
      }).when('/contact', {
        templateUrl: 'views/contact.html',
        controller: 'ContactCtrl',
        menu: 'contact'
      }).when('/signup', {
        templateUrl: 'views/signup.html',
        controller: 'SignupCtrl'
      }).when('/account', {
        templateUrl: 'views/account.html',
        controller: 'AccountCtrl',
        menu: 'account',
        reloadOnSearch: false
      }).when('/reset', {
        templateUrl: 'views/reset.html',
        controller: 'ResetCtrl',
        menu: 'reset'
      }).when('/confirm', {
        templateUrl: 'views/confirmation.html',
        controller: 'ConfirmationCtrl'
      }).otherwise({
        redirectTo: '/home'
      });

    simpleAuthProvider
      .authorizationName('Token')
      .redirectAfterLogin('/account')
      .getToken( function(username, password) {
        var $http = angular.injector(['ng']).get('$http');
        return $http
          .post(window.config.base_api_url + '/rest/sessions', {
            'username': username,
            'password': password
          })
          .then(function(response) {
            var data = response.data;
            return [data.token, { 'user-id': data.userId }];
          });
      });
  }]
);

require('components/i18n');

require('controllers/developer/account');
require('controllers/developer/confirmation');
require('controllers/developer/contact');
require('controllers/developer/global');
require('controllers/developer/livetouch-about');
require('controllers/developer/livetouch-design');
require('controllers/developer/livetouch-docs');
require('controllers/developer/livetouch-res');
require('controllers/developer/opendata');
require('controllers/developer/gbfs');
require('controllers/developer/sign');

require('directives/parse-doc');


var adjustNavbar = function() {
  var navbar2 = $('.navbar-inner');
  var scrollTop = document.body.scrollTop || document.documentElement.scrollTop;
  if (scrollTop > 0) {
    navbar2.addClass('navbar-static-detached');
  } else {
    navbar2.removeClass('navbar-static-detached');
  }
};

$(window).scroll(adjustNavbar);
