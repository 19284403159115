'use strict';

angular.module('developerWebapp')
  .controller('GbfsDataCtrl', ["$scope", "$routeParams", "$location", "$http", "$timeout", "simpleAuth", function ($scope, $routeParams, $location, $http, $timeout, simpleAuth) {

	$scope.baseAPI = window.config.base_api_url;

    // -- Page and menu handling
    // set lang for sub menu
    let updateLang = function () {
      $scope.pages = [
        {id: 'usage', text: $scope.i18n.gbfs.usage},
        {id: 'changelog', text: $scope.i18n.gbfs.changelog}
      ];
      $scope.usageDocLink = 'views/doc/gbfs-usage-'+$scope.i18n.lang+'.md';
      $scope.changelogDocLink = 'views/doc/gbfs-changelog-'+$scope.i18n.lang+'.md';
    };
    $scope.$watch('i18n', updateLang);
    updateLang();

    $scope.currentPage = 'usage';
    if (angular.isDefined($routeParams.page)) {
      $scope.currentPage = $routeParams.page;
    }

    $scope.activate = function (page) {
      $scope.currentPage = page;
      let search = $location.search();
      search.page = page;
      $location.search(search);
    };

    $scope.isActive = function (page) {
      return $scope.currentPage === page;
    };

    // Initialize page
    let startPage = $scope.pages[0].id;
    let param = $location.search().page;
    if (angular.isDefined(param)) {
      startPage = param;
    }
    $scope.activate(startPage);

    let menu = $('.gbfs-menu');
    let menuLimit = menu.position().top - 80;

    let adjustNavbar = function () {
      let scrollTop = document.body.scrollTop || document.documentElement.scrollTop;
      if (scrollTop > menuLimit) {
        menu.addClass('fixed');
      } else {
        menu.removeClass('fixed');
      }
    };

    $(window).scroll(adjustNavbar);

  }]);

