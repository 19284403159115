'use strict';

angular.module('developerWebapp').factory('I18n', function() {
    return {
      'fr': {
        'lang': 'fr',
        'month': ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Aout', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
        'opendata': {
          'getstarted': 'Débuter',
          'static': 'Données statiques',
          'dynamic': 'Données temps-réel',
          'changelog': 'Changements',
          'static_download': 'Télécharger les données statiques des systèmes de vélos en libre-service ci-dessous, sous forme de fichier au format JSON ou CSV ci-dessous.',
          'static_filter': 'Filtre',
          'static_license': 'En cochant cette case j\'accepte la <a href="#/opendata/license">licence</a> d\'utilisation des données.',
          'static_contract': 'Contrat',
          'static_service_name': 'Nom du service',
          'static_cities': 'Villes',
          'expires_in': 'Ces liens expirent dans',
          'expires_seconds': 'secondes',
          'expired': 'Ces liens ont expiré, rafraichissez la page pour en obtenir de nouveaux',
          'none': 'Aucun',
          'path_files': 'Fichiers de trajets'
        },
        'gbfs': {
          'usage': 'Utilisation de l\'API GBFS',
          'changelog': 'Changements'
        },
        'livetouch': {
            'about': 'Motivations',
            'documentation' : 'API & Guides',
            'resources' : 'Ressources',
            'getstarted' : 'Débuter',
            'develop' : 'Développer',
            'guidelines' : 'Conseils',
            'api' : 'API Java',
            'reference' : 'Référence',
            'jsapi' : 'API Javascript',
            'hardware' : 'Matériel',
            'software' : 'Logiciel',
            'getsdk' : 'Émulateur',
            'samples' : 'Exemples',
            'connect' : 'Connectivité',
            'physical' : 'Environnement',
            'ui' : 'Interface utilisateur',
            'requirements' : 'Exigences',
            'footer' : 'Documentation mise à jour le '
        },
        'account': {
        	'email': 'Adresse mail',
        	'email_new': 'Nouvelle adresse mail',
        	'email_confirm': 'Confirmation',
        	'email_update': 'Mettre à jour',
        	'key_title': 'Clé d\'API',
        	'key_nokey': 'Vous n\'avez pas encore de clé d\'API.',
        	'key_btn': 'Demander une clé',
        	'key_txt': 'Vous trouverez ci-dessous votre clé pour les APIs JCDecaux.<br/>Regardez la <a href="#/opendata/vls?page=getstarted"><i class="fa fa-book"></i>&nbsp;documentation</a> pour commencer à l\'utiliser.',
        	'key_status': 'Statut',
        	'deactivate_txt': 'Désactiver le compte',
        	'deactivate_btn': 'Désactiver',
        	'confirmDeactivation': 'Votre compte va être désactivé. En êtes-vous vraiment sûr ?',
        	'ctrl_invalid_mail': 'Adresse mail invalide',
        	'ctrlMailDifferent': 'Les adresses mail sont différentes',
        	'ctrlMailSent': 'Un mail a été envoyé à <strong>{0}</strong> pour confirmation',
        	'ctrl_mail_changed': 'Votre adresse mail a bien été mise à jour',
        	'licence': 'Licence ouverte',
        	'accept': 'J’accepte la <a href="#/opendata/license">licence</a> d’utilisation de données distribuées au travers de l’API.'
        },
        'reset': {
        	'title': 'Changement de mot de passe',
        	'new_password': 'Nouveau mot de passe',
        	'confirm': 'Confirmer le mot de passe',
        	'btn': 'Changer le mot de passe',
        	'error': 'Les mots de passe donnés sont différents',
        	'mailSent': 'Un mail a été envoyé à l\'adresse donnée'
        },
        'signup': {
        	'form_title': 'Créez votre compte'
        },
        'sign': {
          'items': [
            {
              'icon': 'fa fa-globe',
              'title': 'Clé d\'API Open Data',
              'text': 'Accédez en temps-réel aux données des vélos JCDecaux\'s'
            },
            {
              'icon': 'fa fa-book',
              'title': 'Documentation',
              'text': 'Obtenez toute l\'information dont vous avez besoin'
            },
            {
              'icon': 'fa fa-question-circle',
              'title': 'Support',
              'text': 'Posez vos questions'
            }
          ],
          'reset_pass_txt': 'Entrez votre adresse email pour que nous puissions vous envoyer un lien de réinitialisation de votre mot de passe',
          'items_title': 'Votre compte développeur',
          'form_title': 'Connectez-vous à votre compte',
          'email': 'Adresse email',
          'password': 'Mot de passe',
          'signup_txt': 'Vous n\'avez pas de compte ?',
          'signup_btn': 'S\'inscrire',
          'reset_txt': 'Mot de passe perdu ?',
          'reset_btn': 'Réinitialiser',
          'reset_title': 'Réinitialiser votre mot de passe',
          'password_confirm': 'Confirmer mot de passe',
          'signin_btn': 'Connexion',
          'mandatory': 'Champs obligatoires',
          'register': 'S\'inscrire',
          'cancel': 'Annuler',
          'back': 'Retour',
          'ctrlInvalidEmail': 'Adresse mail <strong>invalide</strong>',
          'ctrlMailSent': 'Un email a été envoyé à <strong>{0}</strong>',
          'ctrl_user_activated': 'Votre compte a bien été <strong>activé</strong><br />Vous pouvez maintenant vous connecter',
          'ctrlUserCreated': 'Votre compte a été créé. Vous recevrez un mail de confirmation à <strong>{0}</strong>',
          'ctrlPasswordsInvalid': 'Les mots de passe ne sont pas identiques',
          'ctrl_registration_disabled': 'Les inscriptions sont actuellement bloquées.<br><a href="#/contact">Contactez-nous</a> pour plus d\'informations.',
          'loginError': 'Nous n\'avons pu vous authentifier. Réessayez.'
        },
        'navbar': {
          'home': 'accueil',
          'opendata': 'open data',
          'gbfs' : 'GBFS',
          'bikeSharingSystems': 'Vélos en libre-service',
          'livetouch': 'Livetouch',
          'livetouchabout': 'A propos',
          'livetouchdoc': 'API & Guides',
          'livetouchdesign' : 'Design',
          'livetouchres' : 'Ressources',
          'licence': 'Licence',
          'contact': 'contact',
          'signin': 'connexion',
          'account': 'compte',
          'logout': 'déconnexion'
        },
        'contact': {
          'name': 'Votre nom',
          'firstname': 'Prénom',
          'lastname': 'Nom',
          'mail': 'Votre adresse mail',
          'subject': 'Sujet',
          'content': 'Contenu',
          'send': 'Envoyer',
          'mandatory': 'Champs obligatoires',
          'ctrlSent': 'Votre mail a bien été envoyé'
        },
        'all_right_reserved': 'Tous droits réservés',
        'legal_disclaimer': 'Mentions légales',
        'home': {
          'opendata_head': 'Open Data',
          'opendata_speech': 'Bénéficiez de l’ouverture de nos données pour créer des services innovants.',
          'opendata_full': 'Nous croyons à l’innovation partagée et au potentiel de créativité des communautés pour rendre les villes toujours plus riches et accessibles.<br /> Avec JCDecaux developer, créez des applications et de nouveaux services grâce à une distribution des données facile d’accès et sous <a href="#/opendata/license">« Licence Ouverte »</a>.',
          'vls_head': 'Vélos en libre-service JCDecaux',
          'vls_speech': 'Accédez dès maintenant à toutes nos données vélos !',
          'vls_full': 'De la position des stations à la disponibilité des vélos et places de stationnement en temps réel, réutilisez ces données pour expérimenter de nouvelles représentations ou pour fournir des services innovants et utiles aux usagers. Vous pouvez accéder à ces données au travers du simple téléchargement de fichiers ou d’une API web avancée.'
        },
        'confirm': {
          'activationSucceeded': 'Votre compte a été activé avec succès. Vous pouvez maintenant vous <a href="#/login">connecter</a>.',
          'mailChanged': 'Votre email a bien été changé. Vous pouvez maintenant vous <a href="#/login">connecter</a> avec votre nouvelle adresse.',
          'passwordReset': 'Votre mot de passe a bien été changé. Vous pouver maintenant vous <a href="#/login">connecter</a> avec votre nouveau mot de passe.'
        },
        'errors': function (key) {
          var knownKeys = {
            'CONTENT_IS_INVALID': 'Contenu invalide.',
            'EMAIL_ALREADY_IN_USE': 'Cette adresse mail est déjà utilisée.',
            'EMAIL_IS_INVALID': '',
            'INVALID_CONFIRMATION_CODE': 'Code de confirmation invalide.',
            'INVALID_USER_IDENTIFIER': 'Identifiant utilisateur invalide',
            'KEY_SERVER_IS_UNREACHABLE_AT_THE_MOMENT': 'Le serveur de clé est indisponible pour le moment.',
            'NO_ACCOUNT_REGISTERED_WITH_THIS_EMAIL_ADDRESS': 'Aucun compte enregistré avec cet adresse mail.',
            'NO_STATION_FOUND': 'Pas de station trouvée.',
            'NOT_ACTIVATED_YET': 'Ce compte n\'a pas encore été activé. <br />Vérifiez votre boite mail.',
            'PASSWORD_IS_TOO_SHORT': 'Le mot de passe doit contenir au moins 5 caractères.',
            'PROBLEM_FULFILLING_YOUR_REQUEST': '<strong><i class="icon-warning-sign"></i> Erreur interne.</strong>',
            'REGISTRATION_IS_CURRENTLY_DISABLED': 'Les inscriptions sont bloquées.',
            'SUBJECT_IS_INVALID': 'Sujet invalide.',
            'THIS_EMAIL_RESET_CODE_IS_INVALID': 'Code de changement d\'email invalide.',
            'THIS_PASSWORD_RESET_CODE_IS_INVALID': 'Code de changement de mot de passe invalide.',
            'THIS_USER_ALREADY_HAS_A_KEY': 'Cet utilisateur a déjà une clé',
            'UNABLE_TO_CREATE_CSV_FILE': 'Impossible de créer le fichier CSV.',
            'UNABLE_TO_GET_THE_CONTRACTS': 'Impossible de récupérer les contrats.',
            'UNABLE_TO_GET_THE_STATIONS': 'Impossible de récupérer les stations.',
            'UNABLE_TO_SEND_EMAILS_AT_THE_MOMENT': 'Le service de mail est indisponible.',
            'UNAUTHORIZED': 'Non autorisé',
            'USER_DOES_NOT_EXIST': 'Cet utilisateur n\'existe pas.',
            'USER_HAS_BEEN_DELETED': 'L\'utilisateur a été supprimé.',
            'USER_HAS_BEEN_DELETED_OR_PASSWORD_HAS_CHANGED': 'L\'utilisateur a été supprimé ou son password a changé.',
            'WRONG_LOGIN_OR_PASSWORD': 'Mauvais login ou mot de passe.',
            'YOU_ALREADY_REQUESTED_A_PASSWORD_RESET_BR_PLEASE_CHECK_YOUR_EMAILS': 'Vous avez déjà demandé à changer votre mot de passe.<br />Vérifiez votre boite mail.'
          };
          var message = knownKeys[key];
          return angular.isUndefined(message) ? 'Une erreur est survenue.' : message;
        }
      },
      'en': {
        'lang': 'en',
        'month': ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December' ],
        'opendata': {
          'getstarted': 'Getting started',
          'static': 'Static data',
          'dynamic': 'Real-time data',
          'changelog': 'ChangeLog',
          'static_download': 'Download static data of a bike sharing system as a file in JSON or CSV format.',
          'static_filter': 'Filter',
          'static_license': 'By checking this box, I accept the <a href="#/opendata/license">license</a> to use data.',
          'static_contract': 'Contract',
          'static_service_name': 'Service name',
          'static_cities': 'Cities',
          'expires_in': 'These links expires in',
          'expires_seconds': 'seconds',
          'expired': 'These links have expired, refresh the page to get new links',
          'none': 'None',
          'path_files': 'Path files'
        },
        'gbfs': {
          'usage': 'GBFS API usage',
          'changelog': 'ChangeLog'
        },
        'livetouch': {
            'about': 'Why',
            'documentation' : 'API & Guides',
            'resources' : 'Downloads',
            'getstarted' : 'Get started',
            'develop' : 'Develop',
            'guidelines' : 'Guidelines',
            'api' : 'Java API',
            'reference' : 'Reference',
            'jsapi' : 'Javascript API',
            'hardware' : 'Hardware',
            'software' : 'Software',
            'getsdk' : 'Get the emulator',
            'samples' : 'Samples',
            'connect' : 'Connectivity',
            'physical' : 'Environment',
            'ui' : 'User interface',
            'requirements' : 'Requirements',
            'footer' : 'Documentation updated on '
        },
        'account': {
          'email': 'Email address',
          'email_new': 'New address',
          'email_confirm': 'Confirm new address',
          'email_update': 'Update',
          'key_title': 'API Key',
          'key_nokey': 'You don\'t have an API key yet.',
          'key_btn': 'Request an API key',
          'key_txt': 'Below is your key for the JCDecaux APIs.<br/>To get started, go to the <a href="#/opendata/vls?page=getstarted"><i class="fa fa-book"></i>&nbsp;documentation</a> on how to use it.',
          'key_status': 'Status',
          'deactivate_txt': 'Deactivate account',
          'deactivate_btn': 'Deactivate',
          'confirmDeactivation': 'Your account will be deactivated. Are you really sure?',
          'ctrl_invalid_mail': 'Please provide valid email addresses',
          'ctrlMailDifferent': 'Email addresses don\'t match',
          'ctrlMailSent': 'An email has been sent to <strong>{0}</strong> for confirmation',
          'ctrl_mail_changed': 'Your email address has been successfully changed',
          'licence': 'Open licence',
          'accept': 'I accept the <a href="#/opendata/license">license</a> to use data distributed through the API.'
        },
        'reset': {
          'title': 'Reset password',
          'new_password': 'New password',
          'confirm': 'Confirm new password',
          'btn': 'Reset password',
          'error': 'Passwords are not the same',
          'mailSent': 'An email has been sent to the given address'
        },
        'signup': {
          'form_title': 'Sign up for an account'
        },
        'sign': {
          'items': [
            {
              'icon': 'fa fa-globe',
              'title': 'Open Data API Keys',
              'text': 'Access real time JCDecaux\'s self-service bicycle data'
            },
            {
              'icon': 'fa fa-book',
              'title': 'Documentation',
              'text': 'Get all the information your need about APIs'
            },
            {
              'icon': 'fa fa-question-circle',
              'title': 'Support',
              'text': 'Ask your questions'
            }
          ],
          'reset_pass_txt': 'Please provide your email address so that we can send you a token to change your password',
          'items_title': 'Your developer account',
          'form_title': 'Login to your account',
          'email': 'Email',
          'password': 'Password',
          'signup_txt': 'Don\'t have an account?',
          'signup_btn': 'Sign up',
          'reset_txt': 'Lost your password?',
          'reset_btn': 'Reset password',
          'reset_title': 'Reset your password',
          'password_confirm': 'Confirm password',
          'signin_btn': 'Sign in',
          'mandatory': 'Mandatory fields',
          'register': 'Register',
          'cancel': 'Cancel',
          'back': 'Back',
          'ctrlInvalidEmail': 'Please enter a valid <strong>email address</strong>',
          'ctrlMailSent': 'An email has been sent to <strong>{0}</strong>',
          'ctrl_user_activated': 'Your account has been successfully <strong>activated</strong><br />You can now log in',
          'ctrlUserCreated': 'Your account has been created. You will soon receive a confirmation email at <strong>{0}</strong>',
          'ctrlPasswordsInvalid': 'Passwords are not the same',
          'ctrl_registration_disabled': 'Registration is currently disabled.<br><a href="#/contact">Contact us</a> for more information.',
          'loginError': 'We could not authenticate you. Retry.'
        },
        'navbar': {
          'home': 'home',
          'opendata': 'open data',
          'gbfs' : 'GBFS',
          'bikeSharingSystems': 'Self-service bicycles',
          'livetouch': 'Livetouch',
          'livetouchabout': 'About',
          'livetouchdoc': 'API & Guides',
          'livetouchdesign' : 'Design',
          'livetouchres' : 'Downloads',
          'licence': 'License',
          'contact': 'contact',
          'signin': 'sign in',
          'account': 'account',
          'logout': 'logout'
        },
        'contact': {
          'name': 'Your name',
          'firstname': 'First name',
          'lastname': 'Last name',
          'mail': 'Your email',
          'subject': 'Subject',
          'content': 'Content',
          'send': 'Send',
          'mandatory': 'Mandatory fields',
          'ctrlSent': 'Your email has been sent'
        },
        'all_right_reserved': 'All rights reserved',
        'legal_disclaimer': 'Legal disclaimer',
        'home': {
          'opendata_head': 'Open Data',
          'opendata_speech': 'Enjoy our open data to create innovative services.',
          'opendata_full': 'We believe in shared innovation and the creative potential of communities to make cities ever more inventive and accessible. With JCDecaux developer, create new applications and services through an easy-to-access distibution of data under <a href="#/opendata/license">« Open License »</a>.',
          'vls_head': 'JCDecaux’s self-service bicycles',
          'vls_speech': 'Access our bike data now!',
          'vls_full': 'From the location of the bike stations to the availability of bikes and parking spaces in real time, use our data to experiment new representations or to provide innovative and useful services to users. You can access this data through a simple download or an advanced web API.'
        },
        'confirm': {
          'activationSucceeded': 'Your account has ben activated successfully. You can now <a href="#/login">login</a>.',
          'mailChanged': 'Your email has been changed successfully. You can now <a href="#/login">login</a> with your new address.',
          'passwordReset': 'Your password has ben changed successfully. You can now <a href="#/login">login</a> with your new password.'
        },
        'errors': function (key) {
          var knownKeys = {
            'CONTENT_IS_INVALID': 'Content is invalid.',
            'EMAIL_ALREADY_IN_USE': 'Email already in use.',
            'EMAIL_IS_INVALID': 'The email address is invalid',
            'INVALID_CONFIRMATION_CODE': 'Invalid confirmation code.',
            'INVALID_USER_IDENTIFIER': 'Invalid user identifier',
            'KEY_SERVER_IS_UNREACHABLE_AT_THE_MOMENT': 'Key server is unreachable at the moment.',
            'NO_ACCOUNT_REGISTERED_WITH_THIS_EMAIL_ADDRESS': 'No account registered with this email address.',
            'NO_STATION_FOUND': 'No station found.',
            'NOT_ACTIVATED_YET': 'This account hasn\'t been activated yet. <br />Check your emails.',
            'PASSWORD_IS_TOO_SHORT': 'Password should be at least 5 characters long.',
            'PROBLEM_FULFILLING_YOUR_REQUEST': '<strong><i class="icon-warning-sign"></i> We encountered a problem fulfilling your request.</strong>',
            'REGISTRATION_IS_CURRENTLY_DISABLED': 'Registration is currently disabled.',
            'SUBJECT_IS_INVALID': 'Subject is invalid.',
            'THIS_EMAIL_RESET_CODE_IS_INVALID': 'This email reset code is invalid.',
            'THIS_PASSWORD_RESET_CODE_IS_INVALID': 'This password reset code is invalid.',
            'THIS_USER_ALREADY_HAS_A_KEY': 'This user already has a key.',
            'UNABLE_TO_CREATE_CSV_FILE': 'Unable to create CSV file.',
            'UNABLE_TO_GET_THE_CONTRACTS': 'Unable to get the contracts.',
            'UNABLE_TO_GET_THE_STATIONS': 'Unable to get the stations.',
            'UNABLE_TO_SEND_EMAILS_AT_THE_MOMENT': 'Unable to send emails at the moment.',
            'UNAUTHORIZED': 'Not authorized',
            'USER_DOES_NOT_EXIST': 'User does not exist.',
            'USER_HAS_BEEN_DELETED': 'User has been deleted.',
            'USER_HAS_BEEN_DELETED_OR_PASSWORD_HAS_CHANGED': 'User has been deleted or password has changed.',
            'WRONG_LOGIN_OR_PASSWORD': 'Wrong login or password.',
            'YOU_ALREADY_REQUESTED_A_PASSWORD_RESET_BR_PLEASE_CHECK_YOUR_EMAILS': 'You already requested a password reset.<br />Please check your emails.'
          };
          var message = knownKeys[key];
          return angular.isUndefined(message) ? 'Unexpected error: ' : message;
        }
      }
    };
  });
