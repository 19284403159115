'use strict';

// Global context and object
angular.module('developerWebapp')
  .controller('GlobalCtrl', ["$rootScope", "$route", "I18n", "$scope", "$http", "$q", "simpleAuth", function ($rootScope, $route, I18n, $scope, $http, $q, simpleAuth) {

    $rootScope.REGISTRATION_DISABLED = false;

    // WATCH the logged user and
    // UPDATE the top menu according to it.
    var updateNav = function () {
      $rootScope.navbarItems = [
        {
          'id': 'home',
          'icon': 'fa fa-home',
          'text': $rootScope.i18n.navbar.home,
          'visible': true
        },
        {
          'id': 'opendata',
          'icon': 'fa fa-globe',
          'text': $rootScope.i18n.navbar.opendata,
          'visible': true,
          'submenu': [
            {
              'id': 'license',
              'name': $rootScope.i18n.navbar.licence
            },
            {
              'id': 'vls',
              'name': $rootScope.i18n.navbar.bikeSharingSystems
            }
          ]
        },
        {
          'id': 'gbfs',
          'icon': 'fa fa-globe',
          'text': $rootScope.i18n.navbar.gbfs,
          'visible': true
        },
        {
            'id': 'livetouch',
            'icon': 'lt-livetouch',
            'text': $rootScope.i18n.navbar.livetouch,
            'visible': $rootScope.hasRightsForLivetouch,
            'submenu': [
                {
                  'id': 'about',
                  'name': $rootScope.i18n.navbar.livetouchabout
                },
                {
                  'id': 'design',
                  'name': $rootScope.i18n.navbar.livetouchdesign
                },
                {
                  'id': 'documentation',
                  'name': $rootScope.i18n.navbar.livetouchdoc
                },
                {
                  'id': 'resources',
                  'name': $rootScope.i18n.navbar.livetouchres
                }
              ]
        },
        {
          'id': 'contact',
          'icon': 'fa fa-envelope',
          'text': $rootScope.i18n.navbar.contact,
          'visible': true
        },
        {
          'id': 'login',
          'icon': 'fa fa-sign-in',
          'text': $rootScope.i18n.navbar.signin,
          'visible': !simpleAuth.isLoggedIn()
        },
        {
          'id': 'account',
          'icon': 'fa fa-user',
          'text': $rootScope.i18n.navbar.account,
          'visible': simpleAuth.isLoggedIn()
        },
        {
          'id': 'logout',
          'icon': 'fa fa-sign-out',
          'text': $rootScope.i18n.navbar.logout,
          'visible': simpleAuth.isLoggedIn()
        }
      ];
      $scope.legalLink = 'views/legal-disclaimer/' + $rootScope.i18n.lang + '.md';
    };

    var updateLivetouchRights = function () {
    	return $q(function(resolve, reject) {
	    	if (simpleAuth.isLoggedIn()) {
	            $http.get(window.config.base_api_url + '/rest/users/' + simpleAuth.getParam('user-id'))
	              .then(function (response) {
                  var data = response.data;
                  // return true if lvt is one of module registered for the user, false otherwise
                  $rootScope.hasRightsForLivetouch = (data.tenants !== null && $.inArray('lvt', data.tenants.modules) !== -1);
                  resolve();
	              });
	        } else {
	        	$rootScope.hasRightsForLivetouch = false;
		    	resolve();
	        }
    	});
    };

    var findCurrentMenu = function () {
      // Find current menu
      if (angular.isDefined($route.current)) {
        if(angular.isUndefined($route.current.menu)) {
          $route.current.menu = 'login';
        }
        for (var i in $rootScope.navbarItems) {
          if ($rootScope.navbarItems[i].id === $route.current.menu) {
            $rootScope.navbarCurrentItem = $rootScope.navbarItems[i];
          }
        }
      }
    };

    $rootScope.$on('$routeChangeSuccess', findCurrentMenu);
    $rootScope.$on('$routeChangeSuccess', updateNav);

    $rootScope.$watch( function() {
      return simpleAuth.isLoggedIn();
    }, function() {
      updateLivetouchRights().then(function() {
    	  findCurrentMenu();
    	  updateNav();
      });
    });

    $rootScope.setLang = function (lang) {
      $rootScope.i18n = lang === 'en' ? I18n.en : I18n.fr;
      updateNav();
      findCurrentMenu();
    };

    $rootScope.config = window.config;

    /** Load lang file */
    var lang = navigator.language || navigator.userLanguage;
    $rootScope.setLang(lang.substring(0, 2));

    $scope.navClass = function(page) {
      var currentRoute = 'home';
      if (angular.isDefined($route.current)){
        currentRoute = $route.current.menu;
      }
      if (page === currentRoute) {
        return 'active';
      }
      return '';
    };

    $scope.navSubClass = function(page) {
      if (angular.isDefined($route.current)){
        if (page === $route.current.submenu){
          return 'active';
        }
      }
      return '';
    };

  }]);
