'use strict';

angular.module('developerWebapp')
  .controller('ConfirmationCtrl', ['$scope', '$routeParams', '$http', '$location', 'simpleAuth', function($scope, $routeParams, $http, $location, simpleAuth){

    simpleAuth.logout({ 'redirect': false });

    $scope.activationSucceeded = false;
    $scope.mailConfirmed = false;
    $scope.showPasswordForm = false;
    $scope.passwordReset = false;

    if(angular.isDefined($routeParams.activation)) {
      $http
        .post(window.config.base_api_url + '/rest/users/activate', { 'code' : $routeParams.activation })
        .then(function() {
          $scope.activationSucceeded = true;
        }, function(response) {
          $scope.error = $scope.i18n.errors(response.data);
        });
    } else if(angular.isDefined($routeParams.mail)) {
      $http
        .post(window.config.base_api_url + '/rest/users/mail', {}, {
          'headers': {
            'X-Mail-Change-Token': $routeParams.mail
          }
        })
        .then(function() {
          $scope.mailConfirmed = true;
        }, function(response) {
          $scope.i18n.errors(response.data);
        });
    } else if(angular.isDefined($routeParams.password)) {
      $scope.showPasswordForm = true;
    } else {
      $location.path('/');
    }

    $scope.changePassword = function(){

      if ($scope.password !== $scope.passwordConfirm){
        $scope.error = $scope.i18n.reset.error;
        return;
      }

      $http.put(window.config.base_api_url + '/rest/users/password', {
        'password' : $scope.password
      }, {
        'headers': {
          'X-Password-Reset-Token': $routeParams.password
        }
      }).then(function(){
        $scope.passwordReset = true;
        $scope.showPasswordForm = false;
      }, function(response){
        $scope.error = $scope.i18n.errors(response.data);
      });
    };

  }]);
