'use strict';

angular.module('developerWebapp')
  .controller('PasswordResetCtrl',[ "$scope", "$http", function($scope, $http) {
    $scope.requestPasswordReset = function() {
      $scope.resetInfo = '';
      $scope.resetError = '';
      $http
        .post(window.config.base_api_url + '/rest/users/password/reset', { 'email': $scope.username })
        .then(function() {
          $scope.resetInfo = $scope.i18n.reset.mailSent;
        }, function(response) {
          $scope.resetError = $scope.i18n.errors(response.data);
        });
    };
  }])
  .controller('SignupCtrl', ["$scope", "$http", function($scope, $http){

    $('#email').focus();

    $scope.register = function() {
      if ($scope.registration.password !== $scope.passwordConfirm){
        $scope.error = $scope.i18n.sign.ctrlPasswordsInvalid;
        return;
      }

      $http.post(window.config.base_api_url + '/rest/users', $scope.registration)
        .then(function(){
          $scope.info = $scope.i18n.sign.ctrlUserCreated.format($scope.registration.mail);
        }, function(response){
          $scope.error = $scope.i18n.errors(response.data);
        });
    };

  }]);
