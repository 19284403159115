'use strict';

angular.module('developerWebapp')
  .controller('AccountCtrl', ['$scope', '$rootScope', '$location', '$http', '$routeParams', 'simpleAuth', function($scope, $rootScope, $location, $http, $routeParams, simpleAuth){

    var userId = simpleAuth.getParam('user-id', 0);

    $http
      .get(window.config.base_api_url + '/rest/users/' + userId)
      .then(function(response) {
        $scope.user = response.data;
        simpleAuth.putParam('user-mail', $scope.user.mail);
      })
      .catch(function(error){
        $scope.errors = $scope.i18n.errors(error.data);
      });

    $scope.requestNewMail = function(){
      var newMail = $scope.newMail;
      var newMailConfirm = $scope.newMailConfirm;

      if (newMail !== newMailConfirm){
        $scope.emailError =  $scope.i18n.account.ctrlMailDifferent;
        return;
      }

      $scope.emailError = '';
      $scope.emailInfo = '';

      $http
        .post(window.config.base_api_url + '/rest/users/email/reset', { 'email': newMail })
        .then(function(){
          $scope.emailInfo =  $scope.i18n.account.ctrlMailSent.format(newMail);
        }, function(response){
          $scope.emailError = $scope.i18n.errors(response.data);
        });
    };

    $scope.deactivateAccount = function(){
      var confirm = window.confirm($scope.i18n.account.confirmDeactivation);
      if(confirm) {
        $http.post(window.config.base_api_url + '/rest/users/' + userId + '/deactivate').then(function(){
          $location.path('/logout');
        });
      }
    };

    $scope.requestingKey = false;
    // Called when the user request an API key
    $scope.requestKey = function(){
      $scope.requestingKey = true;
      $http
        .post(window.config.base_api_url + '/rest/users/'+userId+'/key')
        .then(
          function(response){
            var data = response.data;
            $scope.requestingKey = false;
            $scope.user = data;
          },
          function(response){
            $scope.keyError = $scope.i18n.errors(response.data);
            $scope.requestingKey = false;
          });
    };

    $scope.$watch('user', function(newValue) {
      if(angular.isDefined(newValue)) {
        var currentTimestamp = new Date().getTime();
        if(newValue.tenants !== null) {
          $scope.isKeyValid = newValue.tenants.expirationDate === null || currentTimestamp < newValue.tenants.expirationDate;
        }
      }
    });

  }]);
